@font-face {
	font-family: "Phosphor";
	src: url('../font/Phosphor.ttf?nd9bac')
			format("truetype"),
		url('../font/Phosphor.woff?nd9bac')
			format("woff"),
		url('../font/Phosphor.svg?nd9bac#Phosphor')
			format("svg");

	font-weight: normal;
	font-style: normal;
	font-display: block;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'phosphor';
		src: url('../font/phosphor.svg?cuo5mh#phosphor') format('svg');
	}
}
*/
[class^="ph-"],
[class*=" ph-"] {
	display: inline-flex;
}

[class^="ph-"]:before,
[class*=" ph-"]:before {
	font-family: "Phosphor" !important;
	font-style: normal !important;
	font-weight: normal !important;
	speak: never;

	/* display: inline-block; */
	text-decoration: inherit;
	/* width: 1em; */
	/* margin-right: 0.2em; */
	text-align: center;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal !important;
	text-transform: none !important;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */
	/* margin-left: 0.2em; */

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Enable Ligatures */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;
}
.ph-sliders-horizontal:before {
	content: "\e902";
}

.ph-arrow-left:before {
	content: "\e900";
}

.ph-arrow-right:before {
	content: "\e901";
}

.ph-x-light:before {
  content: "\f561";
}
.ph-caret-down-light:before {
  content: "\f2bf";
}
.ph-eye-light:before {
  content: "\f351";
}
.ph-check-light:before {
  content: "\f2e5";
}
.ph-star-light:before {
  content: "\f4ba";
}
.ph-spinner-light:before {
  content: "\f4b3";
}
.ph-heart-light:before {
  content: "\f3a6";
}
.ph-arrows-left-right-light:before {
  content: "\f25b";
}
.ph-shopping-bag-open-light:before {
  content: "\f476";
}
.ph-list-light:before {
  content: "\f3d2";
}
.ph-squares-four-light:before {
  content: "\f4b7";
}
.ph-x-circle-light:before {
  content: "\f560";
}
.ph-warning-circle-light:before {
  content: "\f552";
}
.ph-info-light:before {
  content: "\f3ba";
}
.ph-check-circle-light:before {
  content: "\f2e4";
}
.ph-minus-light:before {
  content: "\f3ef";
}
.ph-plus-light:before {
  content: "\f449";
}
.ph-magnifying-glass-light:before {
  content: "\f3df";
}
.ph-user-light:before {
  content: "\f502";
}
.ph-equals-light:before {
  content: "\f34d";
}
.ph-eyedropper-light:before {
  content: "\f350";
}
.ph-lifebuoy-light:before {
  content: "\f3c4";
}
.ph-file-text-light:before {
  content: "\f35f";
}
.ph-shopping-cart-simple-light:before {
  content: "\f478";
}
.ph-bag-light:before {
  content: "\f27d";
}
.ph-device-mobile-camera-light:before {
  content: "\f32b";
}
.ph-envelope-simple-light:before {
  content: "\f34b";
}
.ph-hourglass-medium-light:before {
  content: "\f3ae";
}
.ph-eye-slash-light:before {
  content: "\f352";
}
.ph-clock-light:before {
  content: "\f2f9";
}
.ph-caret-right-light:before {
  content: "\f2c1";
}
.ph-caret-left-light:before {
  content: "\f2c0";
}
.ph-bag-simple-light:before {
  content: "\f27e";
}
.ph-faders-horizontal-light:before {
  content: "\f355";
}
.ph-link-simple-light:before {
  content: "\f3cf";
}
.ph-star-half-light:before {
  content: "\ef76";
}
.ph-text-align-left-light:before {
  content: "\f4d3";
}
.ph-text-align-right-light:before {
  content: "\f4d4";
}
.ph-funnel-light:before {
  content: "\f37c";
}
.ph-funnel:before {
  content: "\f078";
}
.ph-star-half:before {
  content: "\f2f5";
}
.ph-link-simple:before {
  content: "\f0c7";
}
.ph-faders-horizontal:before {
  content: "\f052";
}
.ph-x:before {
  content: "\f1fd";
}
.ph-caret-down:before {
  content: "\efbc";
}
.ph-eye:before {
  content: "\f04b";
}
.ph-star:before {
  content: "\f1a1";
}
.ph-spinner:before {
  content: "\f199";
}
.ph-heart:before {
  content: "\f0a1";
}
.ph-arrows-left-right:before {
  content: "\ef57";
}
.ph-check:before {
  content: "\efe0";
}
.ph-shopping-bag-open:before {
  content: "\f171";
}
.ph-list:before {
  content: "\f0cb";
}
.ph-squares-four:before {
  content: "\f19e";
}
.ph-x-circle:before {
  content: "\f1fe";
}
.ph-warning-circle:before {
  content: "\f1f0";
}
.ph-info:before {
  content: "\f0b5";
}
.ph-check-circle:before {
  content: "\efe1";
}
.ph-minus:before {
  content: "\f0e9";
}
.ph-plus:before {
  content: "\f143";
}
.ph-magnifying-glass:before {
  content: "\f0da";
}
.ph-user:before {
  content: "\f1de";
}
.ph-equals:before {
  content: "\f049";
}
.ph-shopping-cart-simple:before {
  content: "\f173";
}
.ph-file-text:before {
  content: "\f05b";
}
.ph-lifebuoy:before {
  content: "\f0bf";
}
.ph-eyedropper:before {
  content: "\f04d";
}
.ph-camera:before {
  content: "\efab";
}
.ph-caret-double-left:before {
  content: "\efb9";
}
.ph-caret-double-right:before {
  content: "\efba";
}
.ph-bag:before {
  content: "\ef79";
}
.ph-device-mobile-camera:before {
  content: "\f028";
}
.ph-envelope-simple:before {
  content: "\f047";
}
.ph-hourglass-medium:before {
  content: "\f0aa";
}
.ph-clock:before {
  content: "\eff2";
}
.ph-eye-slash:before {
  content: "\f04e";
}
.ph-caret-right:before {
  content: "\efbe";
}
.ph-caret-left:before {
  content: "\efbd";
}
.ph-bag-simple:before {
  content: "\ef7a";
}
.ph-text-align-left:before {
  content: "\f1b6";
}
.ph-text-align-right:before {
  content: "\f1b7";
}
.ph-check-bold:before {
  content: "\ece0";
}
.ph-heart-fill:before {
  content: "\ea9c";
}
.ph-check-circle-fill:before {
  content: "\e9da";
}
.ph-warning-circle-fill:before {
  content: "\ebea";
}
.ph-star-fill:before {
  content: "\eb9c";
}